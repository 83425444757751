import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #b5def5;
  position: absolute;
  bottom: 0px;
`;

const Autofooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <small>&copy; Copyright {currentYear}, Gabriel de Courval-Paré</small>
    </StyledFooter>
  );
};

export default Autofooter;
