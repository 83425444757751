import React from "react";
import styled from "styled-components";
import { GridLoader } from "react-spinners";

const FullDiv = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PlaceholderLoader = () => {
  return (
    <FullDiv>
      <GridLoader loading={true}/>
    </FullDiv>
  );
};

export default PlaceholderLoader;
