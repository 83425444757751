import React, { Suspense, useEffect, useRef, useState } from "react";
import Autofooter from "./components/Autofooter";
import styled from "styled-components";
import Hamburger from "./components/Hamburger";
import myFace from "./assets/moi.jpg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from "./routes/About";
import Portfolio from "./routes/Portfolio";
import Contact from "./routes/Contact";
import Home from "./routes/Home";
import PlaceholderLoader from "./components/PlaceholderLoader";
import ArcGISGeojsonPicker from "./components/ArcGISGeojsonPicker";
// import GoLView from "./components/GoLView";

const GoLView = React.lazy(() => import("./components/GoLView"));
const ArcGISView = React.lazy(() => import("./components/ArcGISView"));

const DesktopHeader = styled.header`
  @media (min-width: 769px) {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: cornsilk;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MyFace = styled.img`
  width: 7vw;
  max-width: 7vw;
  height: auto;
  border-radius: 50%;
`;

const MobileHeader = styled.header`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 7vh;
    background-color: cornsilk;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const DesktopNavA = styled(Link)`
  padding: 1em;

  &:link {
    color: black;
    text-decoration: none;
  }

  &:visited {
    color: black;
    text-decoration: none;
  }

  &:active {
    color: black;
    text-decoration: none;
  }

  &:hover {
    color: black;
    text-decoration: none;
    background-color: deepskyblue;
    transition: 0.2s;
  }
`;

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  alignitems: center;
  font-family: Aller;
  margin: 0px;
  padding: 0px;
  overflow: auto;
`;

const MyFaceA = styled(Link)`
  margin-left: 3%;
  margin-right: 5%;
  margin-top: 5%;
  width: auto;
  height: auto;
  z-index: 1;
`;

const MobileNav = styled.nav`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: cornsilk;
  box-sizing: border-box;
  padding: 1em;
`;

const MobileA = styled(Link)`
  margin: 4px;
  border: 2px solid;
  border-radius: 4px;
  width: 100%;
  height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.2);

  &:link {
    color: black;
    text-decoration: none;
  }

  &:visited {
    color: black;
    text-decoration: none;
  }

  &:active {
    color: black;
    text-decoration: none;
  }
`;

const BurgerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  padding-top: 2vh;
`;

const MainBodyDiv = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    height: 93vh;
  }

  @media (min-width: 769px) {
    height: 95vh;
  }
`;

function App() {
  const esriEndpointRef = useRef<{
    esriEndpointTitle: string;
    esriEndpointURL: string;
  }>({ esriEndpointTitle: "", esriEndpointURL: "" });

  function toggleMobileNav() {
    let mobileNav = document.getElementById("mobileNav");
    if (!mobileNav) return;
    mobileNav?.style.display === "none"
      ? (mobileNav.style.display = "flex")
      : (mobileNav.style.display = "none");
  }

  return (
    <MainDiv className="App">
      <Router>
        <DesktopHeader>
          <MyFaceA to={"/"}>
            <MyFace src={myFace}></MyFace>
          </MyFaceA>
          <nav id="desktopNav">
            <DesktopNavA to={"/"}>Accueil</DesktopNavA>
            <DesktopNavA to={"/portfolio"}>Portfolio</DesktopNavA>
            <DesktopNavA to={"/contact"}>Contact</DesktopNavA>
            {/* <DesktopNavA to={"/about"}>À propos</DesktopNavA> */}
            {/* <DesktopNavA to={"/arcgispicker"}>Picker</DesktopNavA> */}
          </nav>
        </DesktopHeader>
        <MobileHeader>
          <BurgerDiv>
            <Hamburger onClick={toggleMobileNav}></Hamburger>
          </BurgerDiv>
          <MobileNav id="mobileNav" style={{ display: "none" }}>
            <MobileA to={"/"} onClick={toggleMobileNav}>
              Accueil
            </MobileA>
            <MobileA to={"/portfolio"} onClick={toggleMobileNav}>
              Portfolio
            </MobileA>
            <MobileA to={"/contact"} onClick={toggleMobileNav}>
              Contact
            </MobileA>
          </MobileNav>
        </MobileHeader>
        <MainBodyDiv>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/portfolio/gol"
              element={
                <Suspense fallback={<PlaceholderLoader />}>
                  <GoLView width={300} height={300} cellWidth={2} />
                </Suspense>
              }
            />
            <Route
              path="/portfolio/arcgis"
              element={
                <Suspense fallback={<PlaceholderLoader />}>
                  <ArcGISView
                    esriEndpointRef={esriEndpointRef}
                  />
                </Suspense>
              }
            />
            <Route
              path="/portfolio/arcgispicker"
              element={
                <ArcGISGeojsonPicker
                  esriEndpointRef={esriEndpointRef}
                />
              }
            />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </MainBodyDiv>
      </Router>
      <Autofooter />
    </MainDiv>
  );
}

export default App;
