import React, { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AsyncComponent from "./AsyncComponent";
import ListBox from "./ListBox";

const PickerDiv = styled.div`
  background-color: snow;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PickerSection = styled.section`
  margin-top: 10vh;
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) {
    max-width: 70%;
  }

  @media (max-width: 768px) {
  }
`;

const NoDecoLink = styled(Link)`
  width: 100%;

  &:link {
    color: black;
    text-decoration: none;
  }

  &:visited {
    color: black;
    text-decoration: none;
  }

  &:active {
    color: black;
    text-decoration: none;
  }
`;

type OpenCanEsriEndpoint = {
  name_translated: string;
  hash: string;
  description: string;
  format: string;
  package_id: string;
  mimetype_inner: string;
  url_type: string;
  id: string;
  size: number;
  mimetype: string;
  cache_url: string;
  name: string;
  language: string;
  created: string;
  url: string;
  cache_last_updated: string;
  state: string;
  last_modified: string;
  data_quality: Array<unknown>;
  position: number;
  revision_id: string;
  resource_type: string;
};

const ArcGISGeojsonPicker = ({
  esriEndpointRef,
}: {
  esriEndpointRef: React.MutableRefObject<{
    esriEndpointTitle: string;
    esriEndpointURL: string;
  }>;
}) => {
  const getEsriendpointsRequestRef = useRef(
    new Promise<Array<OpenCanEsriEndpoint>>((resolve, reject) => {
      fetch("https://gdecourval.dev/getesriendpoints", {
        method: "GET",
      }).then(
        (res) => {
          res.json().then(
            (jsonRes) => {
              resolve(jsonRes);
            },
            (err) => {
              reject(`Could not resolve to a JSON: ${err}`);
            }
          );
        },
        (err) => {
          reject(`Getgeojsons rejected: ${err}`);
        }
      );
    })
  );

  return (
    <PickerDiv>
      <PickerSection>
        <AsyncComponent
          promise={getEsriendpointsRequestRef.current}
          promiseHandler={(res): React.ReactNode => {
            let nodes: Array<React.ReactNode> = new Array<React.ReactNode>();
            res.forEach((esriendpoint) => {
              nodes.push(
                <NoDecoLink
                  to={"/portfolio/arcgis"}
                  key={esriendpoint.url + esriendpoint.created}
                >
                  <div
                    style={{ width: "100%" }}
                    onClick={() => {
                      esriEndpointRef.current.esriEndpointTitle = esriendpoint.name_translated;
                      esriEndpointRef.current.esriEndpointURL = esriendpoint.url;
                    }}
                  >
                    {esriendpoint.name_translated}
                  </div>
                </NoDecoLink>
              );
            });
            return (
              <React.Fragment>
                <h2>Choisissez une carte</h2>
                <ListBox>{nodes}</ListBox>
              </React.Fragment>
            );
          }}
          spinner={"BarLoader"}
        />
      </PickerSection>
    </PickerDiv>
  );
};

export default ArcGISGeojsonPicker;
