import React from "react";
import styled from "styled-components";

const FlexedA = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

const Hamburger = ({
  onClick,
}: {
  onClick?: () => any;
}) => {
  return (
    <FlexedA className="icon" onClick={onClick}>
      <i className="fa fa-bars"></i>
    </FlexedA>
  );
};

export default Hamburger;
