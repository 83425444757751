import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import golThumbnail from "../assets/gol.png";
import arcgisThumbnail from "../assets/ArcGIS_logo.png";

const PortfolioDiv = styled.div`
  background-color: snow;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
`;

const PortfolioSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-sizing: border-box;
  padding-top: 7%;

  @media (min-width: 769px) {
    max-width: 70%;
    padding-left: 5em;
  }

  @media (max-width: 768px) {
  }
`;

const SectionEntry = styled.section`
  box-sizing: border-box;
  width: 100%;
  margin-top: 1vh;
  padding: 1em;
  border: 2px gray solid;
  border-radius: 5px;
  &:hover {
    background-color: deepskyblue;
    transition: 0.2s;
  }
`;

const EntryLink = styled(Link)`
  &:link {
    color: black;
    text-decoration: none;
  }

  &:visited {
    color: black;
    text-decoration: none;
  }

  &:active {
    color: black;
    text-decoration: none;
  }

  &:hover {
    color: black;
    text-decoration: none;
  }
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Portfolio = () => {
  return (
    <PortfolioDiv>
      <PortfolioSection>
        <h2>Mes réalisations</h2>
        {/* <SectionEntry>
        <h3>ALGO GÉNÉTIQUE</h3>
        <p>Un algorithme se basant sur l'évolution de l'ADN aux travers les générations afin de converger vers une bonne solution en peu de temps.</p>
      </SectionEntry> */}
        {/* <SectionEntry>
        <h3>DÉVELOPPEMENT GIS</h3>
        <p>Un exemple de GIS fait avec l'API ArcGIS.</p>
      </SectionEntry> */}
        <EntryLink to={"/portfolio/gol"}>
          <SectionEntry>
            <SectionHeading>
              <h3>Game of life</h3>
              <img
                src={golThumbnail}
                alt="Game of life"
                width={"100px"}
                height={"100px"}
              />
            </SectionHeading>
            <p>Ma réalisation de l'algorithme de Conway.</p>
          </SectionEntry>
        </EntryLink>
        <EntryLink to={"/portfolio/arcgispicker"}>
          <SectionEntry>
            <SectionHeading>
              <h3>ArcGIS</h3>
              <img
                src={arcgisThumbnail}
                alt="arcGIS"
                width={"100px"}
                height={"100px"}
              />
            </SectionHeading>
            <p>
              Une application GIS fait avec des données publique provenant du
              gouvernement du Canada.
            </p>
          </SectionEntry>
        </EntryLink>
      </PortfolioSection>
    </PortfolioDiv>
  );
};

export default Portfolio;
