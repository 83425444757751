import React from "react";
import styled from "styled-components";

const ContactDiv = styled.div`
  background-color: snow;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 95vh;
  z-index: -1;
  position: relative;
`;

const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-sizing: border-box;
  overflow-wrap: break-work;
  padding-top: 7%;
  padding-left: 5em;

  @media (min-width: 769px) {
    max-width: 70%;
    padding-left: 5em;
  }

  @media (max-width: 768px) {
    max-width: 95%;
    padding-left: 1em;
  }
`;

const Contact = () => {
  return (
    <ContactDiv>
      <ContactSection>
        <h2>Contact</h2>
        <p>Vous pouvez me rejoindre par les canaux suivants :</p>
        <ul>
          <li>Courriel : gdecourval@gmail.com</li>
          <li>Discord : gdecourval#3616</li>
          <li>CV sur demande.</li>
        </ul>
      </ContactSection>
    </ContactDiv>
  );
};

export default Contact;
