import React from "react";
import styled from "styled-components";

const ListBoxDiv = styled.div`
  padding: 2px;
  box-sizing: border-box;
  overflow: auto;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: start;

  & > *:hover{
    background-color: deepskyblue;
    transition: 0.2s;
    cursor: pointer;
  }
`;

const ListBox = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return <ListBoxDiv>{children}</ListBoxDiv>;
};

export default ListBox;
