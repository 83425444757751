import React, { Fragment, useEffect, useRef, useState } from "react";
import * as spinners from "react-spinners";
import {
  BarLoader,
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClimbingBoxLoader,
  ClipLoader,
  ClockLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  MoonLoader,
  PacmanLoader,
  PropagateLoader,
  PulseLoader,
  PuffLoader,
  RingLoader,
  RiseLoader,
  RotateLoader,
  ScaleLoader,
  SkewLoader,
  SquareLoader,
  SyncLoader,
} from "react-spinners";
import styled from "styled-components";

const SpinnerDiv = styled.div`
    width: 100%,
    height: 100%,
    display: flex,
    flex-direction: column,
    align-items: center;
    justify-content: center;
`;

type Spinner = keyof typeof spinners;
type SpinnerFactory = {
  [Property in Spinner]: React.ReactNode;
};
type PromiseState = "pending" | "fulfilled" | "rejected";

const spinnerFactory: SpinnerFactory = {
  BarLoader: <BarLoader loading={true} />,
  BeatLoader: <BeatLoader loading={true} />,
  BounceLoader: <BounceLoader loading={true} />,
  CircleLoader: <CircleLoader loading={true} />,
  ClimbingBoxLoader: <ClimbingBoxLoader loading={true} />,
  ClipLoader: <ClipLoader loading={true} />,
  ClockLoader: <ClockLoader loading={true} />,
  DotLoader: <DotLoader loading={true} />,
  FadeLoader: <FadeLoader loading={true} />,
  GridLoader: <GridLoader loading={true} />,
  HashLoader: <HashLoader loading={true} />,
  MoonLoader: <MoonLoader loading={true} />,
  PacmanLoader: <PacmanLoader loading={true} />,
  PropagateLoader: <PropagateLoader loading={true} />,
  PulseLoader: <PulseLoader loading={true} />,
  PuffLoader: <PuffLoader loading={true} />,
  RingLoader: <RingLoader loading={true} />,
  RiseLoader: <RiseLoader loading={true} />,
  RotateLoader: <RotateLoader loading={true} />,
  ScaleLoader: <ScaleLoader loading={true} />,
  SkewLoader: <SkewLoader loading={true} />,
  SquareLoader: <SquareLoader loading={true} />,
  SyncLoader: <SyncLoader loading={true} />,
};

const AsyncComponent = <T extends unknown>({
  promise,
  promiseHandler,
  spinner,
}: {
  promise: Promise<T>;
  promiseHandler: (promiseResult: T) => React.ReactNode;
  spinner: Spinner;
}) => {
  const [promiseState, setPromiseState] = useState<PromiseState>("pending");
  const promiseResRef = useRef<T>();

  useEffect(() => {
    promise.then(
      (res) => {
        console.log("Promise fulfilled.");
        promiseResRef.current = res;
        setPromiseState("fulfilled");
      },
      () => {
        console.log("Promise rejected.");
        setPromiseState("rejected");
      }
    );
  }, []);

  useEffect(()=>{
    console.log("Promise state changed, rerendering async comp.");
  }, [promiseState])

  return (
    <React.Fragment>
      {promiseState === "pending" && (
        <SpinnerDiv>{spinnerFactory[spinner]}</SpinnerDiv>
      )}
      {promiseState === "fulfilled" &&
        promiseHandler(promiseResRef.current as T)}
      {promiseState === "rejected" && (
        <span style={{ color: "red" }}>Could not resolve component.</span>
      )}
    </React.Fragment>
  );
};

export default AsyncComponent;
