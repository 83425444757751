import React from "react";
import styled from "styled-components";

const HomeDiv = styled.div`
  background-color: snow;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const WrappedSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
  padding-top: 7%;
  padding-left: 1em;

  @media (min-width: 769px) {
    max-width: 70%;
    padding-left: 5em;
  }

  @media (max-width: 768px) {
    max-width: 95%;
    padding-left: 1em;
  }
`;

const IntroP = styled.p`
  line-height: 1.5;
`;

const FinalSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
`;

const NoMarginH2 = styled.h2`
  margin: 0px;
`;

const Home = () => {
  return (
    <HomeDiv>
      <WrappedSection>
        <NoMarginH2>Bienvenue sur ma page personnelle!</NoMarginH2>
        <IntroP>
          Je m'appelle Gabriel de Courval-Paré et j'étudie le génie logiciel à temps plein
          à l'École de technologie supérieure et j'ai une technique en
          informatique faite au cégep du Vieux-Montréal. Mes études m'ont permis
          de développer des aptitudes dans certains langages de programmation
          tels que C++, Java, Javascript, Typescript et Python ainsi que des
          aptitudes en conception et architecture du logiciel. Mes expériences
          en entreprise m'ont permis de développer mon savoir-faire avec des
          cadriciels comme Node.js, React, et des API comme ArcGIS et aussi
          comment développer mes propres API. Je porte une attention
          particulière à la qualité de mon code et des mes tests unitaires, et
          j'ai aussi une excellente capacité à cerner et comprendre les besoins de mes
          clients.
        </IntroP>
        <FinalSection>
          <p>Au plaisir de vous rencontrer,</p>
          <p></p>
          <p>Gabriel de Courval-Paré</p>
        </FinalSection>
      </WrappedSection>
    </HomeDiv>
  );
};

export default Home;
